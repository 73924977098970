import React, { Component } from 'react';
import loadScript from 'simple-load-script';

import Layout from "../components/layout/layout"
import PreLoader from '../components/preloader';
import SEO from "../components/seo"
import Map from "../components/map"

class ContactPage extends Component {

  
    componentCleanup() { 
      console.log('Cleaned');
      let themeJs = document.getElementById("themeJs");
      document.body.removeChild(themeJs);
      let arenguJs = document.getElementById("arenguJs");
      document.body.removeChild(arenguJs);
      let mapJs = document.getElementById("mapJs");
      document.body.removeChild(mapJs);
      let googleApiJs = document.getElementById("googleApiJs");
      document.body.removeChild(googleApiJs);
    }
    componentWillUnmount() {
      this.componentCleanup();
    }

    componentDidMount() {
      loadScript({url: '/js/theme.js', attrs: { id: 'themeJs'}, inBody: true});
      loadScript({url: 'https://sdk.arengu.com/forms.js', attrs: { id: 'arenguJs'}, inBody: true});
        // loadScript('/js/theme.js',{ inBody: true });
        // loadScript('https://sdk.arengu.com/forms.js',{ inBody: true });
        // loadScript('/js/maps.js', { inBody: true });
        loadScript({url: '/js/maps.js', attrs: { id: 'mapJs'}, inBody: true})
        .then(function(scriptRef) {
            // console.log('success', scriptRef);
            //loadScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyBy8nASnAPlKN7HFQORfwBfluXTObh-g4s&callback=initMap', { inBody: true});
            loadScript({url: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBy8nASnAPlKN7HFQORfwBfluXTObh-g4s&callback=initMap', attrs: { id: 'googleApiJs'}, inBody: true});
        })
        .catch(function(err) {
            console.log(err);
        });
    }

  render() { 
      return ( 
          <Layout>
              <PreLoader/>
              <SEO title="Contact" />
              <Map/>
           </Layout>
      );
  }
}


export default ContactPage
