import React from "react"

const Map = () => (
  <>
    {/* <!-- ============================================--> */}
    {/* <!-- <section> begin ============================--> */}
    <section className="py-0" id="page-contact">
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-6 bg-white pt-5 pb-4 pt-lg-5">
                    <div className="row h-100 align-items-center justify-content-center">
                        <div className="col-lg-8" data-zanim-lg='{"animation":"slide-left","delay":0.6}' data-zanim-trigger="scroll">
                            <h1 className="text-underline d-inline-block mb-4 mb-lg-6">Contact Us</h1>
                            <div className="mt-3">
                              <h5>United Kingdom</h5>
                              <p className="font-weight-bold mb-0">Address:<span className="ml-2 font-weight-normal">5 ST Bernards Road, London </span></p>
                              <p className="font-weight-bold mb-0">Phone:<span className="ml-2 font-weight-normal"><a className="text-700" href="tel:+64211067271">+73 08074271</a></span></p>
                              <p className="font-weight-bold mb-4">E-mail:<span className="ml-2 font-weight-normal font-italic"><a className="text-700" href="mailto:info@sparrow.com">info@wowlabs@co.uk</a></span></p>
                            </div>
                            <div className="mt-3">
                              <h5>Sri Lanka</h5>
                              <p className="font-weight-bold mb-0">Address:<span className="ml-2 font-weight-normal">16, Station Road, Bambalapity</span></p>
                              <p className="font-weight-bold mb-0">Phone:<span className="ml-2 font-weight-normal"><a className="text-700" href="tel:+94757565658">+94 757565658</a></span></p>
                              <p className="font-weight-bold mb-4">E-mail:<span className="ml-2 font-weight-normal font-italic"><a className="text-700" href="mailto:info@sparrow.com">info@wowlabs@co.uk</a></span></p>
                            </div>
                            <div className="mt-3">
                              <h5>USA</h5>
                              <p className="font-weight-bold mb-0">Address:<span className="ml-2 font-weight-normal">167 Perry St, Unit 1F, New York</span></p>
                              <p className="font-weight-bold mb-0">Phone:<span className="ml-2 font-weight-normal"><a className="text-700" href="tel:+19178604410">+1 917 860 4410</a></span></p>
                              <p className="font-weight-bold mb-4">E-mail:<span className="ml-2 font-weight-normal font-italic"><a className="text-700" href="mailto:info@sparrow.com">info@wowlabs@co.uk</a></span></p>
                            </div>
                            <div className="mt-3">
                                <a className="btn btn-dark btn-sm mr-2 " href="https://medium.com/d6-digital"><span className="fab fa-medium" data-fa-transform="grow-6"></span></a>
                                <a className="btn btn-dark btn-sm mr-2" href="https://twitter.com/d6xdigital"><span className="fab fa-twitter" data-fa-transform="grow-6"></span></a>
                                <a className="btn btn-dark btn-sm mr-2" href="https://www.facebook.com/D6xdigital/"><span className="fab fa-facebook-f" data-fa-transform="grow-6"></span></a>
                            </div>
                            

                        </div>
                    </div>
                </div>
                <div className="col-lg-6 px-0">
                    <div className="sticky-top ovrflow-hidden-y">
                        <div className="googlemap min-vh-100 d-none d-lg-block" data-latlng="6.8938388,79.8553885" data-pov='{"heading":260,"pitch":10}' data-scrollwheel="false" data-icon="/img/marker.png" data-zoom="1" data-theme="streetview" data-zanim-lg='{"animation":"zoom-out-slide-left","delay":0.4}'
                             data-zanim-trigger="scroll"></div>
                        {/* <!--Mobile Fallback--> */}
                        <div className="googlemap min-vh-50 d-lg-none" data-latlng="6.893468,79.8538743" data-pov='{"heading":75,"pitch":0}' data-scrollwheel="false" data-icon="/img/marker.png" data-zoom="17" data-theme="Default">
                            <div className="marker-content py-3">
                                <h5>WOW Labs</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- end of .container--> */}
    </section>
    {/* <!-- <section> close ============================--> */}
    {/* <!-- ============================================--> */}
  </>
)

export default Map
